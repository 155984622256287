.searchbar-dropdown {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1111;
  width: 730px;
  box-shadow: 0 0 5px #ccc;
  &:hover {
    p {
      color: #666;
    }
  }
  button {
    border-radius: 0rem;
  }
  .searchbar-field {
    border: none;
    border-bottom: 1px solid #dadada;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 0;
    height: calc(2em + 0.75rem + 2px);
    &:focus {
      outline: none;
      box-shadow: none;
    }
    input {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .search-items-list {
    // overflow-y: auto;
    max-height: 450px;
    overflow-y: auto;
    .list {
      font-size: 14px;
      text-transform: capitalize;
      margin-bottom: 0.7rem;
      transition: all 0.3s;
      &:hover {
        background-color: $primary-color;
        color: $white;
        padding: 0.2rem 0.5rem;
      }
    }
    .title {
      font-size: 17px;
      color: #e65550;
      margin-bottom: 1rem;
      text-transform: capitalize;
    }
  }
  /*-----Loader Css-------*/
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  .loader {
    margin: 10px auto;
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /*-----Loader Css-------*/
}

.arabic-direction .searchbar-dropdown {
  left: 15px !important;
  right: auto;
}
