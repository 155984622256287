.product-details-page {
  .overview {
    padding-top: 1rem;

    @media #{$sm} {
      padding-top: 2rem;
    }

    .text-wrap {
      @media #{$sm} {
        text-align: justify;
      }

      h1,
      h2,
      h3 {
        color: $heading-color;
        font-weight: 600;

        // font-size: 32px;
        @media #{$sm} {
          margin-top: 2rem;
          text-align: left;
        }
      }

      .rating-num {
        display: inline-block;
        margin: 0%;
        color: $heading-color;
        margin-left: 0.5rem;
      }

      .write-review {
        color: $heading-color;
        text-decoration: none;
        margin-left: 5%;
        cursor: pointer;

        &:hover {
          color: $primary-color;
        }
      }

      .detail-text {
        color: $heading-color;
        margin: 1rem 0;
        // font-weight: 300;
      }

      .short-desc {
        p {
          color: $text-secondary;
          font-family: $noto;

          @media #{$sm} {
            text-align: justify;
          }
        }
      }
    }

    .image-wrap {
      //   width: 15%;
      //   display: flex;
      //   flex-direction: row;
      margin-top: 1.75rem;
      display: flex;

      img {
        width: 75px;
        margin-right: 1rem;
      }

      .text-logo {
        color: $heading-color;
        // min-width: 100px;
        text-align: center;
        font-weight: 600;
        /* border-left : 2px solid #888; */
        border-right: 2px solid #888;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 1.5%;
        padding-right: 1.5%;

        &:last-child {
          border-right: none;
        }
      }

      .text-logo-Arabic {
        color: $heading-color;
        // min-width: 100px;
        text-align: center;
        font-weight: 600;
        border-left: 2px solid #888;
        border-right: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 1.5%;
        padding-right: 0;
        padding-left: 1.5%;

        &:last-child {
          border-left: none;
        }
      }
    }

    .buttons-wrap {
      margin-top: 1.75rem;

      @media #{$sm} {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    .buy-favorite-btn {
      display: flex;
      align-items: center;

      svg.icon.heart-icon-wrap {
        width: 30px;
        height: 30px;
        color: #e65550;
      }

      svg.icon {
        width: 30px;
        height: 30px;
        color: #e65550;
      }

      @media #{$sm} {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
      }

      a.heart-icon-wrap.nav-link {
        color: #e65550;
      }
    }

    .buy-now-btn {
      background-color: $primary-color;
      color: $white;
      font-weight: 700;
      width: 175px;
      box-shadow: 0px 2px 5px #bbb;
      text-transform: capitalize;
      margin-right: 4%;
    }

    .partners {
      margin-top: 1rem;
      display: flex;

      img {
        padding: 0.5rem;
        border: 1px solid #f0f0f0;
        border-radius: 4px;
      }

      .product-code-wrap {
        margin-top: 1rem;
        color: $heading-color;
        font-size: small;
      }
    }

    .heart-icon-wrap {
      display: inline-block;
      padding: 1%;
      box-shadow: 0px 2px 5px #bbb;
      background-color: #f0f0f0;
      margin-left: 5%;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 1px 0px #bbb;
      }

      .icon {
        font-size: 24px;
        color: $primary-color;
      }
    }

    .heart-icon-wrap-Arabic {
      margin-right: 5%;

      &:hover {
        box-shadow: 0px 1px 0px #bbb;
      }

      .icon {
        font-size: 24px;
        color: $primary-color;
      }
    }

    .social-media-icons {
      margin-top: 1rem;
      display: flex;

      .social-icon {
        font-size: 24px;
        color: $heading-color;
        margin-right: 0.5rem;
      }
    }

    .toast-header {
      background: $primary-color;
      color: $white;
    }
  }

  .overview-slider {
    position: relative;
    padding: 1rem 0;

    //   min-height: 500px;
    // width: 50%;
    // overflow: scroll;
    .image-gallery-thumbnails-wrapper {
      width: 150px;

      @media #{$sm} {
        display: none;
      }

      .image-gallery-thumbnails-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .image-gallery-thumbnail {
          width: 100%;
          //   height: 30%;
          margin-bottom: 0.75rem;
          padding: 0 0.25rem;
        }
      }
    }

    .image-gallery-slide-wrapper.left,
    .image-gallery-slide-wrapper.right {
      display: inline-block;
      width: calc(100% - 160px);

      @media #{$sm} {
        width: 100%;
      }
    }

    .thumbnail-override {
      height: 33.3%;
      width: 100%;
      //   overflow: hidden;
      border: none;

      // &.thumbnail-video{
      //   content: '\f04b';
      //   position: absolute;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      // }
      &.active {
        .image-gallery-thumbnail-inner {
          border: 1px solid $primary-color;
        }
      }

      .image-gallery-thumbnail-inner {
        box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.2);
        height: 100%;
        border-radius: 6px;

        .image-gallery-thumbnail-image {
          object-fit: cover;
          object-position: center;
          border-radius: 8px;
        }
      }
    }

    .ctrl-button {
      display: inline-block;
      //   width: 40px;
      //   height: 47px;
      position: absolute;
      z-index: +1;
      background: transparent;
      border: none;
      padding: 0;
      text-indent: -9999px;
      transition: all 0.3s ease;
      outline: none;
      color: $primary-color;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: #fff;
        //   box-shadow: 2.457px 1.721px 6px 0px rgb(0 0 0 / 30%);
        box-shadow: 0 0 10px #ccc;
        transition: all 0.3s ease;
      }

      .button-svg {
        width: 11px;
        height: 19px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 10.5px;
        height: 19px;
        display: block;
        margin: auto;
        background: url(./../../../assets/icons/i_arrow_red.svg) center
          no-repeat;
        background-size: 100% auto;
      }

      &.prev {
        left: calc(70px);
        top: calc(-35px + 1rem);

        &::before {
          border-radius: 59% 41% 48% 52% / 55% 39% 61% 45%;
        }

        .button-svg {
          -webkit-transform: rotateZ(180deg);
          -ms-transform: rotate(180deg);
          transform: rotateZ(180deg);
        }
      }

      &.next {
        left: calc(70px);
        bottom: calc(-35px + 1rem);

        &::before {
          border-radius: 41% 59% 52% 48% / 39% 55% 45% 61%;
        }
      }

      @media #{$sm} {
        &.prev,
        &.next {
          left: calc(50% - 16px);
        }
      }
    }
  }

  .magnified-image-container {
    z-index: 99999;
    position: absolute;
    right: 0;
    top: 0;
  }

  .image-gallery-image {
    overflow: hidden;
    height: 450px;

    @media #{$sm} {
      height: 250px;
    }

    img {
      // transform: scale(1.5);
      width: 75%;
      margin: auto;
      height: 100%;
      object-fit: contain;

      &:hover {
        // transform: scale(1.5);
        // transition: all 0.5s;
        // cursor: pointer;
      }
    }

    @media #{$sm} {
      iframe {
        height: 275px !important;
      }
    }
  }

  .react-transform-element {
    // transform: translate(0px, 0px) scale(1.5);
  }

  .variations {
    flex-wrap: wrap;

    .variation-item {
      border: 1px solid $primary-color;
      padding: 0.25rem 1rem;
      font-size: 13px !important;
      // width: 32%;
      margin-right: 1.125%;
      margin-bottom: 1.125%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        background-color: $primary-color;
        color: $white;
      }

      cursor: pointer;

      &:hover {
        background-color: $primary-color;
        color: $white;
      }
    }
  }
}

.arabic-direction .product-details-page .overview-slider .ctrl-button.prev {
  left: 0;
  right: calc(70px);
  top: calc(-35px + 1rem);
}

.arabic-direction .product-details-page .overview-slider .ctrl-button.next {
  left: 0;
  right: calc(70px);
}

.quantity-input {
  margin: 20px 0;
}

.quantity-input input.quantity-input__screen {
  width: 70px;
  text-align: center;
  border-color: #e65550;
  border-left: none;
  border-right: 0;
  color: #666;
  outline: none !important;
}

.quantity-input button.quantity-input__modifier {
  background-color: #e65550;
  color: #fff;
  border: 2px solid #e65550;
}

.quantity-input .quantity-input__modifier--right {
  border-right: none;
}

.quantity-input .quantity-input__modifier--left {
  border-left: none;
}

.buy_cart {
  background-color: #fff !important;
  color: #e65550 !important;
}

@media #{$sm} {
  .product-details-page .overview-slider .ctrl-button.prev {
    left: 0;
    right: calc(70px);
    top: 41%;
    transform: rotate(270deg);
  }

  .product-details-page .overview-slider .ctrl-button.next {
    left: 90%;
    right: 0px;
    top: 41%;
    transform: rotate(-90deg);
  }
}
