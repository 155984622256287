.location-card {
  width: 400px;
  padding: 1rem;
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
  float: right;
  @media #{$sm} {
    width: 100%;
  }
  h6 {
    font-weight: bold;
  }
  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.75rem;
  }
  .details {
    .rating {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      .count {
        color: orange;
      }
      .total-reviewers {
        font-size: 12px;
        color: #0c97fc;
      }
    }
  }
  .directions-icon {
    text-align: center;
    color: #0c97fc;
    margin-left: 1rem;
    cursor: pointer;
    .icon {
      font-size: 36px;
      path {
        stroke: #0c97fc;
      }
    }
  }
}
