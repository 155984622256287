.related-article {
  color: $heading-color;
  .image-wrapper {
    width: 100%;
    height: 400px;
    @media #{$md} {
      height: 100%;
    }
    @media #{$sm} {
      height: 210px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .content-wrapper {
    width: 100%;
    height: 100%;
    background-color: $secondary-color;
    @include padding-vertical(0, "rem");
    @include padding-horizontal(2, "rem");
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media #{$sm} {
      @include padding-vertical(1, "rem");
      @include padding-horizontal(1, "rem");
    }
    @media #{$md} {
      @include padding-vertical(1, "rem");
    }
    h3 {
      font-size: 20px;
    }
    h3.title {
      &:first-child {
        color: $primary-color;
        font-family: $noto;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 2rem;
      }
    }
    p {
      color: $text-secondary;
      @media #{$sm} {
        text-align: justify;
      }
    }
    .discover {
      color: $primary-color;
      font-weight: bold;
      margin-bottom: 37px;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
      span svg {
        margin-top: 0.5rem;
      }
    }
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* STYLES GO HERE */
  .related-article .content-wrapper h3 {
    font-size: 18px;
  }
}

.arabic-container .content-wrapper {
  background-color: red !important;
}
.arabic-container .content-wrapper .discover svg {
  transform: rotate(180deg);
  margin-top: 0.5rem;
}
.arabic-direction .related-article .content-wrapper h3.title:first-child {
  margin-bottom: 3rem;
}
.arabic-direction .related-article .content-wrapper h3 {
  margin-bottom: 2rem;
}
.aligned-article-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
