.drawer-menu {
  .drawer-logo {
    width: 100%;
    height: calc(40px + 2rem);
    padding: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }
}
.arabic-direction {
  text-align: right;
  direction: rtl;
}
.mob_lang_us img, .mob_lang_ar img {
  width: 2rem !important;
  height: 2rem !important;
}
.mob_lang_us, .mob_lang_ar {
  width: 48% !important;
  float: left;
}
button.dropdown-item {
  font-size: 14px;
}
