.skin-cycle-section {
  padding-top: 2rem;
  h1 {
    border-bottom: 3px solid $primary-color;
    display: inline-block;
    padding-bottom: 0.25rem;
    font-size: 36px;
    font-family: $noto;
    color: $heading-color;
    margin: 0%;
    @media #{$sm} {
      font-size: 32px;
    }
  }
  .skin-cycle-row {
    margin-top: 2rem;
    .image-wrap {
      width: 100%;
      height: 100%;
      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @media #{$sm} {
          height: auto;
        }
      }
      @media #{$sm} {
        height: auto;
      }
    }
    .text-wrap {
      h5 {
        color: $heading-color;
        font-family: $noto;
        margin-bottom: 1rem;
      }
      p {
        color: $text-secondary;
        font-family: $noto;
        line-height: 2rem;
      }
      @media #{$sm} {
        margin-top: 1rem;
      }
    }
    @media #{$sm} {
      // margin-top: 0%;
    }
  }
}
