.promo-banner {
  direction: ltr;
  background-image: url("./../../../assets/images/banner4.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .carousel__inner-slide {
    direction: ltr;
    height: 550px;
    z-index: -1;
    @media #{$md} {
      height: 375px;
      margin: auto;
    }
    @media #{$sm} {
      height: 175px;
      margin: auto;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media #{$md} {
        object-fit: cover;
      }
      @media #{$sm} {
        object-fit: cover;
      }
    }
    .promo-item-image {
      position: relative;
      height: 100%;
      width: 100vw;
      .slider-text {
        position: absolute;
        top: 0%;
        left: 0%;
        // color: white;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1rem 5rem;
        h1 {
          margin-bottom: 2rem;
          font-weight: bold;
        }
        p {
          padding: 0 1rem;
          margin-bottom: 2rem;
        }
        button {
          background-color: #6dcff6;
          border-color: #6dcff6;
        }
        @media #{$sm} {
          width: 100%;
          align-items: flex-start;
          h1 {
            margin-bottom: 1rem;
            font-size: 22px;
            text-align: left;
          }
          p {
            margin-bottom: 0;
            display: none;
          }
        }
        @media #{$md} {
          padding: 1rem 2rem;
         }
      }
    }
  }
  // .horizontalSlider___281Ls {
  //   // z-index: -1 !important;
  // }
  // iframe {
  //   display: block; /* iframes are inline by default */
  //   background: #fff;
  //   border: none; /* Reset default border */
  //   height: 1000; /* Viewport-relative units */
  //   width: 100vw;
  // }
  // .vp-center {
  //   .player {
  //     max-width: 100% !important;
  //     width: 100% !important;
  //   }
  // }

  .ctrl-button {
    display: inline-block;
    width: 40px;
    height: 47px;
    position: absolute;
    top: calc(50% - 24px);
    translate: (-50%, -50%);
    z-index: +1;
    background: transparent;
    border: none;
    padding: 0;
    text-indent: -9999px;
    transition: all 0.3s ease;
    outline: none;
    @media #{$sm} {
      width: 35px;
      height: 42px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: #fff;
      //   box-shadow: 2.457px 1.721px 6px 0px rgb(0 0 0 / 30%);
      box-shadow: 0 0 10px #ccc;
      transition: all 0.3s ease;
    }
    .button-svg {
      width: 11px;
      height: 19px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 10.5px;
      height: 19px;
      display: block;
      margin: auto;
      background: url(./../../../assets/icons/i_arrow_red.svg) center no-repeat;
      background-size: 100% auto;
    }
    &.prev {
      left: 15px;

      &::before {
        border-radius: 59% 41% 48% 52% / 55% 39% 61% 45%;
      }
      .button-svg {
        -webkit-transform: rotateZ(180deg);
        -ms-transform: rotate(180deg);
        transform: rotateZ(180deg);
      }
    }
    &.next {
      right: 15px;
      &::before {
        border-radius: 41% 59% 52% 48% / 39% 55% 45% 61%;
      }
    }
  }
}

// .ctrl-button {
//   display: inline-block;
//   width: 40px;
//   height: 47px;
//   position: absolute;
//   top: calc(50% - 24px);
//   translate: (-50%, -50%);
//   z-index: 999999999999;
//   background: transparent;
//   border: none;
//   padding: 0;
//   text-indent: -9999px;
//   transition: all 0.3s ease;
//   outline: none;
//   @media #{$sm} {
//     width: 35px;
//     height: 42px;
//   }
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     margin: auto;
//     background: #fff;
//     //   box-shadow: 2.457px 1.721px 6px 0px rgb(0 0 0 / 30%);
//     box-shadow: 0 0 10px #ccc;
//     transition: all 0.3s ease;
//   }
//   .button-svg {
//     width: 11px;
//     height: 19px;
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     width: 10.5px;
//     height: 19px;
//     display: block;
//     margin: auto;
//     background: url(./../../../assets/icons/i_arrow_red.svg)
//       center no-repeat;
//     background-size: 100% auto;
//   }
//   &.prev {
//     left: 15px;
//     // z-index: 999 !important;
//     &::before {
//       border-radius: 59% 41% 48% 52% / 55% 39% 61% 45%;
//     }
//     .button-svg {
//       -webkit-transform: rotateZ(180deg);
//       -ms-transform: rotate(180deg);
//       transform: rotateZ(180deg);
//     }
//   }
//   &.next {
//     // z-index: 999 !important;
//     right: 15px;
//     &::before {
//       border-radius: 41% 59% 52% 48% / 39% 55% 45% 61%;
//     }
//   }
// }

.iframe-wrap {
  position: relative;
  // padding: 56.25%;
  padding: 28.25%;
  @media #{$sm} {
    // padding: 0;
  }
  .propo-video-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // @media #{$sm} {
    //   height: 85%;
    //   background-color: #000;
    // }
  }
}

// .promo-banner {
//   .carousel__inner-slide {
//     height: 550px;
//     @media #{$sm} {
//       height: 250px;
//       margin: auto;
//     }
//     img {
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//     }
//   }

//   .ctrl-button {
//     display: inline-block;
//     width: 40px;
//     height: 47px;
//     position: absolute;
//     top: calc(50% - 24px);
//     translate: (-50%, -50%);
//     z-index: +1;
//     background: transparent;
//     border: none;
//     padding: 0;
//     text-indent: -9999px;
//     transition: all 0.3s ease;
//     outline: none;
//     &::before {
//       content: "";
//       position: absolute;
//       top: 0;
//       bottom: 0;
//       left: 0;
//       right: 0;
//       margin: auto;
//       background: #fff;
//       //   box-shadow: 2.457px 1.721px 6px 0px rgb(0 0 0 / 30%);
//       box-shadow: 0 0 10px #ccc;
//       transition: all 0.3s ease;
//     }
//     .button-svg {
//       width: 11px;
//       height: 19px;
//       position: absolute;
//       top: 0;
//       bottom: 0;
//       left: 0;
//       right: 0;
//       width: 10.5px;
//       height: 19px;
//       display: block;
//       margin: auto;
//       background: url(./../../../assets/icons/i_arrow_red.svg)
//         center no-repeat;
//       background-size: 100% auto;
//     }
//     &.prev {
//       left: 15px;
//       &::before {
//         border-radius: 59% 41% 48% 52% / 55% 39% 61% 45%;
//       }
//       .button-svg {
//         -webkit-transform: rotateZ(180deg);
//         -ms-transform: rotate(180deg);
//         transform: rotateZ(180deg);
//       }
//     }
//     &.next {
//       right: 15px;
//       &::before {
//         border-radius: 41% 59% 52% 48% / 39% 55% 45% 61%;
//       }
//     }
//   }
// }

// .arabic-direction .image-menu ul li a .link-txt {
//   font-family: $arabic-font !important;
// }
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  .promo-banner .carousel__inner-slide .promo-item-image .slider-text {
    padding: 1rem 1rem;
  }
}