.advisor-category-grid {
  margin-top: 2rem;
  .baby-care-content {
    border-radius: 6px;
    box-shadow: 0px 0px 25px rgba($color: #000000, $alpha: 0.05);
    // min-height: 400px;
    margin-bottom: 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:hover {
      box-shadow: 0px 0px 25px rgba($color: #000000, $alpha: 0.08);
    }
    .baby-care-image-wrap {
      text-align: center;
      width: 100%;
      height: 225px;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .baby-care-image {
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }
    .baby-care-text {
      // padding: 3% 5%;
      margin-top: 1rem;
      color: $primary-color;
      @media #{$sm} {
        text-align: justify;
      }
      p {
        color: $text-secondary;
        font-size: 14px;
        height: 100px;
        // font-weight: 300;
      }
      .read-more {
        color: $primary-color;
        cursor: pointer;
      }
      // @include simpleButton(200, none);
    }
    @media #{$sm} {
      margin-bottom: 2rem;
      min-height: auto;
    }
  }
}

.arabic-direction .links-wrap .m-btn-simple-next span::after {
  left: 15px !important;
  right: auto !important;
  transform: rotateY(180deg);
}
.arabic-direction .links-wrap .m-btn-simple-back span::after {
  transform: rotate(4deg);
  right: 15px !important;
  left: auto !important;
}
.arabic-direction
  .milk-drinks-section
  .content-wrap
  .description
  ul
  li::before {
  margin-left: 0.7rem;
}

.arabic-direction .milk-drinks-section .content-wrap .description strong {
  font-weight: bold !important;
}
.arabic-direction .product-details-page .tab-item-wrapper .spec-content h3 {
  font-weight: 600;
}
.milk-drinks-section .content-wrap .description ol li {
  color: $text-secondary;
}