.official-social-media-section {
  margin-bottom: 1rem;
  h3,
  h4 {
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 1rem;
    margin-top: 2rem;
    color: $heading-color;
    font-family: $noto;
    font-weight: normal;
    font-size: 20px;
  }
  .image-wrap {
    width: 50px;
    height: 50px;
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .links-wrap {
    margin-bottom: 1rem;
    text-decoration: underline;
    a {
      color: $text-secondary;
      cursor: pointer;
      .icon {
        color: $primary-color;
        margin-left: 0.4rem;
        font-size: 18px;
      }
      &:hover {
        color: $primary-color;
      }
    }
  }
}
