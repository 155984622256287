.baby-care-section {
  margin-top: 2rem;
  .baby-care-content {
    border-radius: 6px;
    box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.1);
    min-height: 400px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .baby-care-image-wrap {
      text-align: center;
      width: 100%;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .baby-care-image {
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }
    .baby-care-text {
      // padding: 3% 5%;
      margin-top: 1rem;
      @media #{$sm} {
        text-align: justify;
      }
      h6 {
        color: $primary-color;
        // font-weight: bold;
      }
      p {
        color: $text-secondary;
        // font-weight: 300;
      }
      .read-more {
        color: $primary-color;
        cursor: pointer;
      }
      // @include simpleButton(200, none);
    }
    @media #{$sm} {
      margin-bottom: 2rem;
      min-height: auto;
    }
  }
}
