.bottom-navigator-wrapper {
  display: none;
}

@media only screen and (max-width: 600px) {
  .bottom-navigator-wrapper {
    display: block;
    margin-top: 56px;
    z-index: 100;
    .MuiBottomNavigation-root {
      justify-content: space-around;
      align-items: center;
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 100;
      box-shadow: 0px -4px 6px -2px #ccc;
      a {
        // display: flex;
        // flex-direction: column;
        text-align: center;
        color: $heading-color;
        font-weight: 600;
        .tab-icon {
          font-size: 24px;
          font-weight: 600;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
