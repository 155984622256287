.third-trimester-section {
  margin-top: 3rem;
  .image-wrap {
    width: 100%;
    height: 320px;
    overflow: hidden;
    border-radius: 6px;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media #{$sm} {
        height: auto;
      }
    }
    @media #{$sm} {
      margin: 1rem 0%;
      height: auto;
    }
  }
  .text-wrap {
    h5 {
      color: $primary-color;
      font-family: $noto;
      margin-bottom: 1rem;
      @media #{$sm} {
        margin-top: 0rem;
      }
    }
    p {
      color: $text-secondary;
      font-family: $noto;
      // line-height: 2rem;
      @media #{$sm} {
        text-align: justify;
      }
    }
  }
  @media #{$sm} {
    margin-top: 0%;
  }
}
