.wishlist-card {
  font-family: $noto;
  color: $text-secondary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  // background-color: #fafafa;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.1);
  position: relative;
  @media #{$sm} {
    flex-wrap: wrap;
    height: auto;
  }
  .remove-icon {
    position: absolute;
    font-size: 32px;
    right: -12px;
    top: -12px;
    color: $primary-color;
    cursor: pointer;
  }
  .image {
    width: 15%;
    height: calc(150px - 2rem);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media #{$sm} {
      width: 25%;
    }
  }
  .details {
    width: 50%;
    padding-left: 1rem;
    @media #{$sm} {
      width: 75%;
    }
    h5 {
      font-weight: 500;
      font-size: 16px;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
    .badge-primary {
      background-color: $primary-color;
    }
    .logos-wrap {
      margin-top: 1rem;
      display: flex;
      .text-logo {
        font-size: 12px;
        color: $heading-color;
        // width: 100px;
        text-align: center;
        span {
          background-color: #eaeaea;
          color: $text-secondary;
          display: inline-block;
          margin-right: 1rem;
        }
        // font-weight: 600;
        /* border-left: 2px solid #888; */
        // border-right: 1px solid #888;
        // border-left: 1px solid #888;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-direction: column;
      }
    }
  }

  .buttons {
    width: 35%;
    @media #{$sm} {
      width: 100%;
      display: flex;
      margin-left: calc(25% + 1rem);
    }
    .simple-buttons {
      display: flex;
      // flex-wrap: wrap;
      @media #{$sm} {
        width: 50%;
        flex-wrap: wrap;
      }
      .view {
        width: 50%;
        padding: 0 0.25rem;
        @media #{$sm} {
          width: 100%;
          margin-top: 0.5rem;
        }
        button {
          border-radius: 4px !important;
          width: 100%;
        }
      }
      .buy {
        width: 50%;
        padding: 0 0.25rem;
        @media #{$sm} {
          width: 100%;
          margin-top: 0.5rem;
        }
        button {
          border-radius: 4px;
          width: 100%;
        }
      }
    }

    .social-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 1rem;
      @media #{$sm} {
        flex-wrap: wrap;
        small {
          width: 100%;
          text-align: center;
        }
      }
      svg {
        cursor: pointer;
        font-size: 16px;
        color: $primary-color;
        // &.facebook {
        //   color: #1972eb;
        // }
        // &.instagram {
        //   color: #b70396;
        // }
        // &.youtube {
        //   color: #f70000;
        // }
        // border-radius: 50%;
        // overflow: hidden;
        &:hover {
          transform: scale(1.2);
          transition: all 0.4s ease;
        }
      }
      @media #{$sm} {
        width: 50%;
        padding-top: 0;
      }
    }
  }
}
