.privacy-protection-section {
  padding: 2rem 0rem;
  direction: ltr !important;
  text-align: left !important;
  font-family: $noto !important;
  @media #{$sm} {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    // text-align: justify;
  }
  .content-wrap {
    // padding-bottom: 1rem;
    h3 {
      color: $heading-color;
      font-family: $noto;
      padding-bottom: 0.25rem;
      border-bottom: 3px solid $primary-color;
      display: inline-block;
      margin-bottom: 1rem;
      font-weight: normal;
      font-size: 20px;
      @media #{$sm} {
        text-align: left;
      }
    }
    p {
      color: $text-secondary;
      font-family: $noto;
      @media #{$sm} {
        text-align: justify;
      }
    }
    ol {
      padding-left: 1rem;
      @media #{$sm} {
        text-align: justify;
      }
      li {
        color: $text-secondary;
        margin-bottom: 0.5rem;
        ol {
          list-style-type: lower-roman;
          li {
            color: $text-secondary;
            margin-bottom: 0rem;
            ol {
              list-style-type: lower-alpha;
            }
          }
        }
      }
    }
    section {
      text-align: end;
      margin-top: 1.5rem;
      span {
        color: $text-secondary;
      }
    }
    h5 {
      background: #e5e5e5;
      padding: 0.5rem;
      color: $heading-color;
      font-family: $noto;
      margin: 1rem 0rem;
      font-weight: normal;
      font-size: 18px;
      @media #{$sm} {
        text-align: left;
      }
    }
    h6 {
      color: $heading-color;
      font-family: $noto;
      position: relative;
      padding-left: 20px;
      margin-top: 1.5rem;
      font-size: 18px;
      @media #{$sm} {
        text-align: left;
      }
      &::before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background-color: $primary-color;
        position: absolute;
        display: block;
        width: 12px;
        height: 3px;
      }
    }
    ul {
      margin-bottom: 1rem;
      list-style-type: none;
      padding-left: 0;
      li {
        display: flex;
        align-items: center;
        color: $text-secondary;
        font-family: $noto;
        padding-left: 2px;
        &::before {
          content: "\2713";
          color: $white;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          margin-right: 0.5rem;
          padding: 0.25rem 0.25rem;
          background-color: $primary-color;
          border-radius: 41% 59% 44% 63% / 43% 43% 57% 57%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: 15px;
        }
      }
    }
    table {
      border-collapse: collapse;
      margin: 2rem 0rem;
      td {
        border: 1px solid #999;
        padding: 1rem;
        text-align: left;
        #email {
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
  .image-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    .image {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      cursor: pointer;
    }
    .share-icon {
      cursor: pointer;
      color: $primary-color;
      font-size: 18px;
      margin-top: 0.7rem;
    }
  }
  .text-wrap {
    height: 100%;
    display: grid;
    place-items: center;
    p {
      color: $text-secondary;
      font-family: $noto;
    }
  }
}
