.contact-page {
  .contact-form {
    padding: 2rem 0;
    color: $heading-color;
    font-family: $noto;
    @media #{$sm} {
      padding: 1rem 0;
    }
    .section-title-underlined {
      // padding-bottom: 2rem;
      h1 {
        // font-weight: 600;
        font-family: $noto;
        color: $heading-color;
        display: inline-block;
        position: relative;
        font-size: 36px;
        border-bottom: 3px solid $primary-color;
        padding-bottom: 0.25rem;
        font-weight: normal;
        margin: 0rem;
        // &::after {
        //   content: "";
        //   position: absolute;
        //   transform: translate(-115%, 1400%);
        //   width: 75%;
        //   border-bottom: 4px solid $primary-color;
        // }
        @media #{$sm} {
          font-size: 28px;
          font-weight: 500;
        }
      }
      @media #{$sm} {
        h2 {
          font-size: 28px;
          // &::after {
          //   position: absolute;
          //   transform: translate(-115%, 1000%);
          // }
        }
      }
    }
    .form-wrapper {
      padding-top: 2rem;
      @media #{$sm} {
        padding-top: 1rem;
      }
      h5 {
        font-weight: normal;
        margin-bottom: 2rem;
        font-size: 16px;
        @media #{$sm} {
          font-size: 18px;
          margin-bottom: 1rem;
        }
      }
      form {
        label {
          // font-weight: 300;
          font-size: 16px;
        }
        input {
          border: none;
          background-color: #f6f6f6;
          border-radius: 6px;
          color: $heading-color;
          font-size: 16px;
          @include padding-vertical(1, "rem");
          height: 45px;
        }
        .form-control:focus {
          border-color: transparent;
          outline: 0;
          box-shadow: none;
        }
        textarea {
          border: none;
          background-color: #f6f6f6;
          border-radius: 6px;
          color: $heading-color;
          font-size: 14px;
          @include padding-vertical(1, "rem");
          resize: none;
        }
        button {
          margin-top: 0.5rem;
        }
      }
    }
  }
  .location-image {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    img {
      // @extend .img-thumbnail;
      // box-shadow: 0 0 50px rgba($color: #000000, $alpha: .3);
      // width: 100%;
      width: 90%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
      // padding: 2rem;
      margin: auto;
    }
    @media #{$sm} {
      margin-top: 2rem;
      padding: 0 1rem;
      display: none;
      img {
        width: 100%;
      }
    }
  }
}
