.post-pregnancy-header {
  color: $heading-color;
  @include padding-vertical(2, "rem");
  //   border-bottom: 5px solid #eaeaea;
  @media #{$sm} {
    @include padding-vertical(1, "rem");
  }
  .section-title-underlined {
    // padding-bottom: 2rem;
    h1 {
      // font-weight: 600;
      font-family: $noto;
      color: $heading-color;
      display: inline-block;
      position: relative;
      font-size: 36px;
      border-bottom: 3px solid $primary-color;
      padding-bottom: 0.25rem;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 1.5rem !important;
      @media #{$sm} {
        margin-bottom: 1rem;
        font-size: 28px;
        font-weight: 500;
      }
    }
  }
  .image-wrapper {
    width: 100%;
    height: 350px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .description-list {
    // margin-top: 2rem;
    @media #{$sm} {
      margin-top: 0rem;
      text-align: justify;
    }
    p {
      margin-bottom: 1.5rem;
    }
    ul {
      padding-left: 0;
      li {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        &::before {
          content: "\2713";
          color: #ffffff;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          margin-right: 0.5rem;
          padding: 0.25rem 0.25rem;
          background-color: #e65550;
          border-radius: 41% 59% 44% 63% / 43% 43% 57% 57%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
        }
      }
      .check-icon {
        color: $white;
        font-size: 16px;
        vertical-align: middle;
      }
      .icon-pebble {
        margin-right: 0.5rem;
        padding: 0.25rem 0.1rem;
        background-color: $primary-color;
        border-radius: 41% 59% 44% 63% / 43% 43% 57% 57%;
        display: inline-flex;
        align-items: center;
      }
    }
  }
  .description-list-Arabic{
    // margin-top: 2rem;
    @media #{$sm} {
      margin-top: 0rem;
      text-align: justify;
    }
    p {
      margin-bottom: 1.5rem;
    }
    ul {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 50px;
      li {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        &::before {
          content: "\2713";
          color: #ffffff;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          margin-right: 0;
          margin-left: 0.5rem;
          padding: 0.25rem 0.25rem;
          background-color: #e65550;
          border-radius: 41% 59% 44% 63% / 43% 43% 57% 57%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
        }
      }
      .check-icon {
        color: $white;
        font-size: 16px;
        vertical-align: middle;
      }
      .icon-pebble {
        margin-right: 0.5rem;
        padding: 0.25rem 0.1rem;
        background-color: $primary-color;
        border-radius: 41% 59% 44% 63% / 43% 43% 57% 57%;
        display: inline-flex;
        align-items: center;
      }
    }
  }
  .massage-text {
    @media #{$sm} {
      text-align: justify;
    }
    h6 {
      margin-bottom: 1rem;
      margin-top: 2rem;
      @media #{$sm} {
        margin-top: 1rem;
      }
    }
  }
}
