.disclosure-policy-section {
  padding: 2rem 0rem 1rem 0rem;
  @media #{$sm} {
    padding-top: 1.5rem;
    text-align: justify;
  }
  .info-wrap {
    padding-bottom: 1rem;
    p {
      color: $text-secondary;
      font-family: $noto;
      margin-bottom: 0rem;
    }
  }
  .text-wrap {
    padding-bottom: 1rem;
    h3,
    h4 {
      font-family: $noto;
      color: $heading-color;
      padding-bottom: 0.25rem;
      border-bottom: 3px solid $primary-color;
      display: inline-block;
      margin-bottom: 1rem;
      font-weight: normal;
      font-size: 20px;
    }
    p {
      font-family: $noto;
      color: $text-secondary;
      margin-bottom: 0rem;
    }
  }
}
