@keyframes expand {
  0% {
    transform: scale(0);
    // transform: translate3d(0%,0%,0);
  }
  100% {
    transform: scale(1);
    // transform: translate3d(-50%,-50%,0);
  }
}

@keyframes egg_anim {
  0% {
    border-radius: 41% 59% 37% 63% / 43% 43% 57% 57%;
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
  100% {
    border-radius: 41% 59% 37% 63% / 43% 43% 57% 57%;
  }
}
.product-item {
  // height: 400px;
  width: 100%;
  padding-bottom: 100%;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-bottom: 42.773%; /* (438 × 100 / 1024) */
  @media #{$sm} {
    height: 180px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
  .menu-item {
    z-index: 10;
    color: $white !important;
    font-size: 23px;
    font-family: $noto;
    font-weight: 500;
    text-decoration: none;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media #{$sm} {
      font-size: 16px;
      width: 100%;
    }
    &:hover {
      color: $white !important;
    }
  }
  &:hover {
    &::after {
      background-color: rgba($color: #fff, $alpha: 1);
      transition: all 0.3s ease-in-out;
    }
    .menu-item {
      padding: 0.5rem;
      font-size: 23px;
      font-weight: 500;
      &::after {
        z-index: -1;
        background-color: $mandy;
        // border-radius: 41% 59% 37% 63% / 43% 43% 57% 57%;
        // border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
        border-radius: 50% 50% 50% 70%/50% 50% 70% 60%;
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        height: 250px;
        margin: -125px 0 0 -40%;
        // transform: translate3d(-50%,-50%,0);
        padding: 0.5rem;
        //   box-shadow: 2.457px 1.721px 12px 0px rgb(0 0 0 / 30%);
        box-shadow: 0 0 10px
          rgba($color: #000000, $alpha: 0.3);
        transition: all 2s ease;
        // animation: expand 0.5s;
        animation: egg_anim 30s infinite 0s,
          //?
          expand 1.5s ease;
      }

      @media #{$sm} {
        font-size: 16px;
        &::after {
          width: 95%;
          height: 150px;
          margin: -75px 0 0 -47%;
        }
      }
    }
  }
}
