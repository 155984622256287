.first-trimester-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
  .first-time-father-heading {
    border-bottom: 3px solid $primary-color;
    display: inline-block;
    padding-bottom: 0.25rem;
    font-size: 36px;
    font-family: $noto;
    color: $heading-color;
    margin: 0%;
    font-weight: normal;
    @media #{$sm} {
      font-size: 28px;
      font-weight: 500;
    }
  }
  .first-trimester-row {
    margin-top: 2rem;
    .image-wrap {
      width: 100%;
      height: 320px;
      overflow: hidden;
      border-radius: 6px;
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media #{$sm} {
          height: auto;
        }
      }
      @media #{$sm} {
        margin: 2rem 0% 1rem 0%;
        height: auto;
      }
    }
    .text-wrap {
      h5 {
        color: $primary-color;
        font-family: $noto;
        margin-bottom: 1rem;
        @media #{$sm} {
          margin-top: 0.3rem;
        }
      }
      h6 {
        color: $heading-color;
        font-family: $noto;
      }
      p {
        color: $text-secondary;
        font-family: $noto;
        // line-height: 2rem;
        @media #{$sm} {
          text-align: justify;
        }
      }
    }
    @media #{$sm} {
      margin-top: 0%;
    }
  }
  @media #{$sm} {
    margin-top: 1rem;
  }
}
