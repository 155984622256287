.navbar-wrap {
  // position: relative;

  .navbar {
    background: $white !important;
    @include padding-horizontal(2, "rem");
    padding-top: 1rem;
    z-index: 999;
    padding-bottom: 0%;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: calc(100% - 1px);
      left: 0px;
      right: 0px;
      height: 10px;
      background: url(./../../assets/images/bg_header_pc.svg) bottom center
        no-repeat;
      background-size: 100% 220px;
      opacity: 1;
      transition: all 0.2s ease;
      pointer-events: none;
      height: 132px;
      z-index: 999;
      // visibility: hidden;
    }

    .nav-link {
      color: $heading-color !important;
      font-family: "Noto Sans JP", sans-serif !important;
    }

    &.hide-curve {
      box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
      &::before {
        // display: none;
        // visibility: hidden;
        opacity: 0;
        // animation: hide 2s ease-out;
      }
    }
    @media #{$md} {
      &::before {
        display: none;
        visibility: hidden;
        content: "";
      }
      .navbar-icons {
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
    @media #{$sm} {
      &::before {
        display: none;
        visibility: hidden;
        content: "";
      }
      .navbar-icons {
        flex-direction: row;
        justify-content: space-evenly;
      }
    }

    .navbar-brand {
      // width: 180px;
      width: 150px;
      img {
        width: 100%;
        @media #{$md} {
          width: 200px;
        }
        @media #{$sm} {
          width: 43%;
        }
      }
      @media #{$md} {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0%;
        padding-bottom: 1rem;
        align-items: center;
        z-index: 999999;
      }
      @media #{$sm} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0%;
        padding-bottom: 1rem;
        z-index: 999999;
      }
    }
    .nav-link {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .nav-toggle-override {
      font-size: 30px;
      margin-right: 1rem;
    }
    .searchbar-icon {
      position: relative;
    }
  }
  .nav-text {
    .nav-link {
      &.active {
        color: $primary-color !important;
      }
    }
  }
  .dropdown-menu{
    min-width: 5rem;
    text-align: left;
  }
  .btn:focus, .btn.focus{
    box-shadow: none;
  }
}
// .arabic-direction {
//    {
//     font-family: "Droid Arabic Naskh", serif;
//   }
// }
.arabic-direction .navbar-wrap .navbar .nav-link {
  font-size: 16px;
}
.navbar-wrap .navbar .nav-link:hover {
  color: #e65550 !important;
}
