@import "./../../../styles/variables";

.goodtoknow-card {
  margin-bottom: 3rem;
  .good-to-know-row-reverse {
    @media #{$sm} {
      display: flex;
      flex-direction: column-reverse;
    }
    .text-wrap-good {
      h5 {
        color: $primary-color;
        font-family: $noto;
        margin-bottom: 1rem;
        @media #{$sm} {
          text-align: left;
        }
      }
      p {
        color: $text-secondary;
        font-family: $noto;
        // line-height: 2rem;
        @media #{$sm} {
          text-align: justify;
        }
      }
      @media #{$sm} {
        margin-top: 1rem;
      }
    }
    .image-wrap-good {
      width: 100%;
      height: 320px;
      background-color: #f0f0f0;
      border-radius: 6px;
      overflow: hidden;
      .good-to-know-image {
        width: 100% !important;
        height: 100%;
        object-fit: cover;
        @media #{$sm} {
          width: 100%;
          height: auto;
        }
        @media #{$md} {
          height: auto;
        }
      }
      @media #{$sm} {
        height: auto;
      }
      @media #{$md} {
        height: auto;
      }
    }
  }
  @media #{$sm} {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.good-to-know-page {
  font-family: $noto;
  color: $heading-color;
  h1 {
    padding-bottom: 0.25rem;
    border-bottom: 3px solid $primary-color;
    display: inline-block;
    margin-bottom: 2rem;
    font-size: 36px;
    font-family: $noto;
    font-weight: normal;
    color: $heading-color;
    margin: 0rem;
    padding-top: 2rem;
    margin-bottom: 2rem;
    @media #{$sm} {
      font-size: 28px;
      font-weight: 500;
      margin-bottom: 1rem;
      padding-top: 1rem;
    }
  }
}

.arabic-direction {
  .goodtoknow-card .good-to-know-row-reverse .text-wrap-good h5,
  .goodtoknow-card .good-to-know-row-reverse .text-wrap-good p,
  .accordion-item-wrap .accordion-card .accordion-card-header h6,
  .accordion-item-wrap .accordion-card,
  .baby-care-tab-container p {
    font-family: "Droid Arabic Naskh", serif !important;
  }
  p {
    font-size: 1rem;
  }
}
