.product-details-page {
  .feature-tabs {
    @include padding-vertical(2, "rem");
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      color: #495057;
      background-color: #fff;
      border-color: #dee2e6 #dee2e6 #fff;
      border-top: 2px solid $primary-color;
      color: $primary-color;
      position: relative;
    }
    .nav-tabs .nav-link {
      // margin-bottom: -1px;
      // border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      background: #f0f0f0;
      margin-right: 0.25rem;
      width: 17%;
      border: 1px solid #dadada;
      border-bottom: 0;
      font-size: 16px;
      font-weight: 600;
      color: $heading-color;
      text-align: center;
      @media #{$sm} {
        // min-width: 100%;
        width: 31%;
        margin-bottom: 0.25rem;
        font-size: 14px;
        // font-weight: normal;
        padding: 0.5rem 0;
      }
    }
  }

  .tab-item-wrapper {
    width: 100%;
    // height: 65vh;
    overflow-x: scroll;
    border-left: 1px solid #e0d9d9;
    border-right: 1px solid #e0d9d9;
    border-bottom: 1px solid #e0d9d9;
    padding: 2rem;
    color: $heading-color;
    @media #{$sm} {
      padding: 1.5rem;
      height: 30vh;
    }
    .content {
      width: 100%;
      // height: 100%;
      // padding: 1%;
      // object-fit: cover;
      p {
        @media #{$sm} {
          text-align: justify;
        }
      }
    }
    .img-content {
      width: 80% !important;
      display: block;
      margin: 0px auto;
    }

    .description {
      // padding: 1rem;
      h5,
      h4,
      h3 {
        font-weight: bold;
        color: $heading-color;
        font-size: 24px;
        margin-bottom: 1.5rem;
      }
      ul {
        // padding: 0;
        list-style-type: circle;
        @media #{$sm} {
          padding: 0;
          text-align: justify;
        }
        li {
          margin-bottom: 1rem;
          color: $heading-color;
          font-size: 14px;
          font-weight: 600;
          strong {
            color: $heading-color;
          }
          li {
            color: $text-secondary;
            font-weight: normal;
          }
        }
        p {
          margin: 0;
          color: $mandy;
          font-size: 16px;
          @media #{$sm} {
            font-size: 14px;
            text-align: justify;
          }
        }
      }
      p {
        color: $text-secondary;
        font-size: 14px;
      }
      .description-para {
        color: $text-secondary;
      }
    }

    h5.spec-title {
      font-weight: bold;
      color: $heading-color;
      font-size: 24px;
    }
    .spec-content {
      display: flex;
      padding: 1rem 0;
      width: 75%;
      align-items: center;
      color: $text-secondary;
      @media #{$sm} {
        width: 100%;
      }
      h3 {
        font-size: 14px;
        color: $text-secondary;
      }
      p {
        font-size: 14px;
      }
      img {
        width: 60px;
        height: 60px;
        margin-right: 1rem;
      }
    }
    .downloads {
      h5 {
        font-weight: bold;
        color: #666;
      }
      .download-box {
        display: inline-flex;
        box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.1);
        border-radius: 6px;
        padding: 1rem;
        margin-top: 1rem;
        align-items: center;
        &:hover {
          box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
          cursor: pointer;
        }
        .download-icon {
          font-size: 64px;
          color: $primary-color;
        }
        p {
          margin: 0;
        }
        p.filename {
          font-weight: bold;
          color: $text-secondary;
          @media #{$sm} {
            font-size: 14px;
          }
        }
        p.date {
          font-size: 13px;
          color: $text-secondary;
        }
      }
    }
  }
}

.product-details-page .tab-item-wrapper .description ul p span {
  color: #666;
}
