// modified data
@keyframes slideanimation {
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: 50%;
    opacity: 0.5;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}

@keyframes paragraphanimation {
  0% {
    opacity: 0;
    display: none;
  }
  50% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

.accordion-slider {
  br {
    margin: 1rem;
    content: "";
    display: block;
  }
  @media #{$sm} {
    display: none;
  }
  @media #{$md} {
    display: none;
  }
  .panels {
    display: flex;
  }
  .accordion-link {
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
  }
  .open {
    flex: 4 1;
    &::after {
      content: "";
      background-color: rgba($color: #000000, $alpha: 0.2);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }
  .open-active {
    text-align: auto;
  }
  .panel > * {
    margin: 0;
    width: 100%;
    /* transition: transform 0.5s; */
    // /* flex: 1 0 auto; */
    display: block;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    // flex-direction: column;
  }
  .panel {
    transition: all 800ms 0ms cubic-bezier(0.215, 0.61, 0.355, 1);
    position: relative;
    .content {
      position: absolute;
      display: none;
      text-align: left;
      bottom: 70%;
      z-index: 2;
      font-family: $noto;
      @include padding-horizontal(3, "rem");
      h3 {
        font-family: $noto;
        font-size: 40px;
        margin-bottom: 2rem;
      }
      p {
        font-weight: 500;
      }
      span,
      pre {
        color: $white;
      }
    }
    &:focus {
      outline: none !important;
    }
    &:active {
      pointer-events: none;
    }
    &:hover {
      @extend .open, .open-active;
      & > * {
        text-align: left;
      }
      .content {
        // display: block;
        transition: all 0.4s;
        animation: slideanimation 0.3s ease-in-out;
        padding-right: 30%;
        align-self: center;
        flex-direction: column;
        /* position: absolute; */
        top: 50%;
        transform: translateY(-50%);
        /* line-height: 370px; */
        height: 100%;
        width: 100%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        font-family: $noto !important;
        h1,
        h3 {
          opacity: 0;
        }
        p {
          opacity: 0;
        }
        span {
          opacity: 0;
        }
        h1,
        h3 {
          animation: paragraphanimation 0.3s linear forwards //?
            0.55s;
        }
        p {
          animation: paragraphanimation 0.3s linear forwards //?
            0.55s;
        }
        span {
          animation: paragraphanimation 0.3s linear forwards //?
            0.55s;
        }
      }
      h3.main-heading {
        display: none;
      }
    }

    h3.main-heading {
      font-family: $noto;
      font-size: 40px;
      position: absolute;
      margin-top: 160px;
      text-transform: uppercase;

      @media #{$sm} {
        font-size: 24px;
      }
      // height: 40%;
    }

    // p{
    //   height: 60%;
    // }
  }
  // &.arabic-text {
  //   text-align: right !important;
  //   h1,
  //   h2,
  //   h3,
  //   h4,
  //   h5,
  //   h6,
  //   p,
  //   pre,
  //   span,
  //   a {
  //     text-align: right !important;
  //   }
  // }
}

.accordian-mobile {
  br {
    margin: 1rem;
    content: "";
    display: block;
  }
  display: none;
  // @media #{$md} {
  //   display: block;
  // }
  @media #{$sm} {
    display: block;
  }
  @media #{$md} {
    display: block;
  }
  width: 100%;
  // height: 600px;
  overflow: hidden;

  /*Time for some styling*/
  // margin: 100px auto;
  // box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
  // -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);
  // -moz-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.35);

  .content {
    display: none;
    width: 100%;
  }

  /*A small hack to prevent flickering on some browsers*/
  ul {
    width: 100%;
    height: 100%;
    display: block;
    padding: 0;
    margin-bottom: 0;
    /*This will give ample space to the last item to move
  instead of falling down/flickering during hovers.*/
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    &:hover {
      transition: all 0.5s;
      // li {
      //   height: 180px;
      // }
    }
    li {
      position: relative;
      display: block;
      width: 100%;
      // height: 180px;
      // min-height: 180px;
      border-left: 1px solid #888;

      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);

      /*Transitions to give animation effect*/
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;

      background-size: cover;
      background-position: center;

      // display: flex;
      // justify-content: center;
      // align-items: center;
      a {
        text-decoration: none;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        height: auto;
        transition: all 0.5s;
        display: block;
        // animation: slideanimation 3s linear forwards;
        .content {
          display: block;
          padding: 2rem;
          color: $white !important;
          height: 100%;
          animation: slideanimation 1s linear forwards;
          a {
            color: $white;
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }
          }
        }
        h3.main-heading {
          display: none;
        }
      }
      .accordion {
        width: 100%;
      }
      .card,
      .card-header {
        background: transparent !important;
        border: none !important;
      }
      .card-header {
        padding: 3rem 1.5rem;
        @media #{$md} {
          padding: 6rem 1.5rem;
        }
      }
    }
  }
}


.accordian-mobile li img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image_title {
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  left: 0;
  bottom: 0;
  width: 640px;
}
.image_title a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 20px;
  font-size: 16px;
}

.panel {
  box-shadow: none !important;
}

 .arabic-direction .content {
   text-align: right !important;
//   font-family: $arabic-font !important;
 }
// .arabic-direction .accordion-slider .panel h3.main-heading,
// .accordion-slider .panel:hover .content,
// .accordion-slider .panel .content h3 {
//   font-family: $arabic-font !important;
// }
