.ques-ans-section {
  .page-heading-wrapper {
    margin-top: 2rem;
    h1 {
      border-bottom: 3px solid $primary-color;
      font-size: 36px;
      color: $heading-color;
      display: inline-block;
      font-family: $noto;
      padding-bottom: 0.25rem;
      margin: 0%;
      font-weight: normal;
      @media #{$sm} {
        font-size: 28px;
        font-weight: 500;
      }
    }
    @media #{$sm} {
      margin-top: 1rem;
    }
  }
  .ques-ans-wrapper {
    // border: 1px solid $primary-color;
    border-radius: 6px;
    margin-top: 3rem;
    // margin-bottom: 3rem;
    box-shadow: 0 5px 25px
      rgba($color: #000000, $alpha: 0.1);
    .row-container {
      padding: 5% 0%;
      .ques-ans-heading {
        margin: 0%;
        color: $heading-color;
        @media #{$sm} {
          margin-bottom: 12px;
          font-size: 1.6rem;
        }
      }
      .ques-ans-image-wrap {
        width: 100%;
        height: 250px;
        .ques-ans-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      .ques-ans-content {
        width: 100%;
        height: 100%;
        // padding-right: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .ques-ans-text {
          color: $text-secondary;
          font-family: $roboto;
          margin-top: 2rem;
          // line-height: 220%;
          // font-size: 24px;
          // letter-spacing: 1px;
          // font-weight: 300;
          @media #{$sm} {
            margin-top: 0%;
          }
        }
        .read-more {
          color: $primary-color;
          cursor: pointer;
        }
        // @include simpleButton(200, none);
        @media #{$sm} {
          padding: 0%;
          font-size: 18px;
          text-align: justify;
        }
      }
    }
    @media #{$sm} {
      margin-top: 2rem;
    }
  }
}
