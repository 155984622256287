.review-wrapper {
  .review-star {
    background-color: #f6f6f6;
    border-radius: 6px;
    display: grid;
    place-items: center;
  }

  //!----------------Login Styles
  padding: 1rem 2rem;
  position: relative;
  color: $heading-color;
  // .close-icon-wrapper {
  // text-align: right;
  .close-icon {
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: $primary-color;
  }
  // }
  .review-heading {
    text-align: center;
    position: relative;
    color: $text-secondary;
    span {
      font-weight: 500;
      font-size: 2rem;
      font-family: $roboto;
      @media #{$xsm} {
        font-size: 24px;
      }
    }
    &::after {
      content: "";
      position: absolute;
      transform: translate(-89%, 2500%);
      width: 80%;
      border-bottom: 2px solid $primary-color;
      @media #{$xsm} {
        transform: translate(-98%, 2000%);
        width: 80%;
      }
    }
  }
  .login-sub-heading {
    text-align: center;
    margin: 5% 0% 3% 0%;
    color: $text-secondary;
    p {
      font-size: 1rem;
    }
  }
  .google-login-col {
    display: flex;
    justify-content: flex-end;
    .google-login-button {
      background-color: #4c8bf5;
      font-size: 12px;
      font-weight: 300;
      border-radius: 6px;
      width: 100%;
      border: none;
      outline: none;
      box-shadow: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    @media #{$sm} {
      justify-content: center;
      margin-bottom: 10px;
    }
  }
  .facebook-login-col {
    .facebook-login-button {
      background-color: #3c5899;
      font-size: 12px;
      font-weight: 300;
      border-radius: 6px;
      width: 100%;
      border: none;
      outline: none;
      box-shadow: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    @media #{$sm} {
      display: flex;
      justify-content: center;
    }
  }
  .login-divider-wrap {
    position: relative;
    margin-top: 3rem;
    &::after {
      content: "Or";
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-bottom: 0%;
      position: absolute;
      transform: translate(700%, -106%);
      color: #d3d3d3;
      box-shadow: 0px 0px 5px #e3e3e3;
      @media #{$xsm} {
        transform: translate(525%, -106%);
      }
      @media #{$tiny} {
        transform: translate(375%, -106%);
      }
    }
  }
  .form-wrapper {
    padding-top: 0.5rem;
    h5 {
      font-weight: normal;
      margin-bottom: 2rem;
    }
    form {
      label {
        font-weight: 300;
        font-size: 14px;
      }
      input {
        border: none;
        background-color: #f6f6f6;
        border-radius: 6px;
        color: $heading-color;
        font-size: 14px;
        @include padding-vertical(1, "rem");
        height: 40px;
      }
      .form-control:focus {
        border-color: transparent;
        outline: 0;
        box-shadow: none;
      }
      textarea {
        border: none;
        background-color: #f6f6f6;
        border-radius: 6px;
        color: $heading-color;
        font-size: 14px;
        @include padding-vertical(1, "rem");
        resize: none;
      }
      .login-button-col {
        text-align: center;
        button {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
