.feedback-section {
  padding: 3rem 0rem 2rem 0rem;
  .feedback-heading {
    padding-bottom: 1rem;
    h6 {
      color: $heading-color;
      margin-bottom: 0%;
      font-family: $noto;
      &::after {
        margin-left: 3px;
        content: "*";
      }
    }
    @media #{$sm} {
      font-size: 22px;
    }
  }
  .form-input-wrap {
    height: 10vh;
    .input {
      border: none;
      background-color: #f6f6f6;
      border-radius: 6px;
      color: $heading-color;
      font-size: 16px;
      @include padding-vertical(1, "rem");
      height: 45px;
    }
    .input-group-text {
      background-color: #f6f6f6;
      border-left: none;
      height: 45px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    .form-icon {
      font-size: 16px;
      color: $text-secondary;
    }
  }
  .text-area-wrap {
    .text-area {
      border: none;
      background-color: #f6f6f6;
      border-radius: 6px;
      color: $heading-color;
      font-size: 16px;
      @include padding-vertical(1, "rem");
      resize: none;
    }
    .review-icon {
      background-color: #f6f6f6;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      padding-top: 18px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .input-group-text {
    background-color: $white;
    border: none;
  }
  .form-group{
    margin-bottom: 0;
  }
  a:focus,
  input:focus,
  textarea:focus,
  button:focus,
  .btn:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
}
