@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes egg_anim {
  0% {
    border-radius: 41% 59% 37% 63% / 43% 43% 57% 57%;
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
  100% {
    border-radius: 41% 59% 37% 63% / 43% 43% 57% 57%;
  }
}

@keyframes egg_anim_gr {
  0% {
    border-radius: 44% 56% 33% 67% / 44% 54% 46% 56%;
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
  100% {
    border-radius: 44% 56% 33% 67% / 44% 54% 46% 56%;
  }
}

@keyframes egg_anim_yw {
  0% {
    border-radius: 68% 32% 62% 38% / 44% 38% 62% 56%;
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
  100% {
    border-radius: 68% 32% 62% 38% / 44% 38% 62% 56%;
  }
}

.image-menu {
  width: 100%;
  height: 550px;
  background-size: cover;
  background-position: center;
  position: relative;
  bottom: 0;
  right: 0;
  width: 100%;
  animation: opacity 1s ease;
  // transition: background-image 0.1s;
  @media #{$sm} {
    height: 450px;
    background-position: center;
  }
  ul {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    @media #{$sm} {
      flex-wrap: wrap;
      padding-left: 0;
    }
    li {
      // width: 175px;
      width: 200px;
      @include margin-horizontal(1, "rem");
      @media #{$sm} {
        width: calc(50% - 2rem);
      }
      a {
        display: block;
        height: 204px;
        position: relative;
        &:hover {
          transform: scale(1.1);
          transition: all 1s;
          &::before {
            animation: egg_anim 30s infinite 0s;
          }
        }
        &::before {
          background: $mandy;
          border-radius: 41% 59% 37% 63% / 43% 43% 57% 57%;
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 164px;
          margin: -82px 0 0 -50%;
          //   box-shadow: 2.457px 1.721px 12px 0px rgb(0 0 0 / 30%);
          transition: -webkit-transform 0.3s ease;
          transition: transform 0.3s ease;
          transition: transform 0.3s ease, -webkit-transform 0.3s ease;
          @media #{$sm} {
            height: 146px;
          }
        }

        .link-txt {
          font-family: $noto;
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          display: block;
          position: absolute;
          top: 50%;
          left: 20px;
          right: 20px;
          text-align: center;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          //   padding-bottom: 30px;
        }
      }
      &:nth-child(1) {
        a {
          &::before {
            background: $mandy;
          }
        }
      }
      &:nth-child(2) {
        a {
          &::before {
            background: $brightsun;
            // border-radius: 37% 59% 63% 41% / 57% 57% 43% 43%;
            border-radius: 44% 56% 33% 67% / 44% 54% 46% 56%;
          }
          &:hover {
            &::before {
              animation: egg_anim_gr 30s infinite 0s;
            }
          }
        }
      }
      &:nth-child(3) {
        a {
          &::before {
            background: $spray;
            // border-radius: 68% 32% 62% 38% / 44% 38% 62% 56%;
            border-radius: 68% 32% 62% 38% / 44% 38% 62% 56%;
          }
          &:hover {
            &::before {
              animation: egg_anim_yw 30s infinite 0s;
            }
          }
        }
      }
      &:nth-child(4) {
        a {
          &::before {
            background: $cornflower-blue;
          }
        }
      }
    }
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
/* STYLES GO HERE */
.image-menu ul li a .link-txt {
  font-size: 14px;
  }
}
